import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import { dispatch } from 'helpers'

import 'modules/bootstrap'
import 'modules/measure'
import 'modules/elemTracker'
//import 'modules/observeGroup'
import 'modules/rotator'
import 'modules/screen'
import 'modules/mouseSpy'
import 'modules/form'
import 'modules/scrollRotator'
import 'modules/scrollSync'
import 'modules/counter'
import 'modules/accordion'
import 'modules/slider'
import 'modules/masonry'
import 'modules/tooltips'
import 'modules/parallax'
import 'modules/tilt'
import 'modules/lightbox'
import 'modules/modal'
import 'modules/sizesCollection'

const FETCH_CONFIG = {
  mode: 'cors',
  credentials: 'same-origin',
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  }
}

Alpine.plugin(intersect)

try {
  // measure scrollbar width
  new ResizeObserver(() => {
    if (window.getComputedStyle(document.documentElement).getPropertyValue('overflow-y') !== 'hidden') {
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${window.innerWidth - document.documentElement.clientWidth}px`
      )
    }
  }).observe(document.body)
} catch {}

Alpine.store('app', {
  masthead: {
    height: 0,
  },
  scrollY: window.scrollY,
  syncHeight(key, el) {
    this[key].height = el.offsetHeight

    try {
      new ResizeObserver((entries) => {
        this.$nextTick(() => {
          const newHeight = entries[0].contentRect.height
          if (this[key].height !== newHeight) this[key].height = newHeight
        })
      }).observe(el)
    } catch (error) {
      // no resize observer possible
      console.log(error)
    }
  },
  syncScrollHeight(el, key, context) {
    try {
      new ResizeObserver(() => {
        this.$nextTick(() => {
          const newHeight = el.scrollHeight
          if (context[key] !== newHeight) context[key] = newHeight
        })
      }).observe(el)
    } catch (error) {
      // no resize observer possible
      console.log(error)
    }

    return el.scrollHeight
  },
  init() {
    window.addEventListener('scroll', () => {
      Alpine.store('app').scrollY = window.scrollY
    })
  },
})

window.app = {
  settings: {},
  track(...args) {
    if (!window._paq || typeof window._paq !== 'object') return
    window._paq.push(['trackEvent', ...args])
  },
  setHash: (hash) => {
    history.pushState({}, '', hash)
    dispatch('programmatichashchange')
  },
  scrollTo: (destination) => {
    if (!destination) return
    document.body.scrollTo({
      top:
        destination.getBoundingClientRect().top +
        document.body.scrollTop -
        (Alpine.store('app').masthead.height || 0),
      behavior: 'smooth',
    })
  },
  submitAndHide: (btn, parent, cb) => {
    const form = btn.closest('form')
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        parent.style.display = 'none'
        //window.location.reload()
        if (cb) cb()
      }
    }
    xhr.open('POST', form.action)
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(serializeForm(form, btn))
  },
}

// let currentHash = window.location.hash
// let currentAnchor = currentHash?.substr(1)?.length
//   ? document.getElementById(currentHash.substr(1))
//   : null
// const scrollToHandler = (e) => {
//   const { target } = e
//   const currentTarget = target.closest(
//     `a[href^="#"]:not([data-scrollhandler-ignore])`
//   )
//   if (!currentTarget) return
//   const { pathname, hash } = currentTarget
//   if (window.location.pathname === pathname && hash?.substr(1)?.length) {
//     currentHash = hash
//     window.app.setHash(currentHash)
//     e.preventDefault()
//   }
// }
// const hashchangeHandler = () => {
//   currentHash = window.location.hash
//   currentAnchor = document.getElementById(currentHash.substr(1))
//   window.app.scrollTo(currentAnchor)
// }

// Alpine.nextTick(() => {if (currentAnchor) window.app.scrollTo(currentAnchor)})
// document.documentElement.addEventListener('click', scrollToHandler)
// window.addEventListener('hashchange', hashchangeHandler)
// window.addEventListener('programmatichashchange', hashchangeHandler)

// reading progress
const readingProgrssSource = document.querySelector('.reading-progress-source')
if (readingProgrssSource) {
  import('modules/readingProgress').then((prog) =>
    prog.default(readingProgrssSource)
  )
}

// accordions
if (document.querySelectorAll('.hljs').length) {
  import('highlight.js/styles/monokai.css')
}

if (document.getElementById('matomo-opt-out')) {
  import('modules/matomo').then((mod) => mod.default())
}


function serializeForm(form, clickedButton) {
  let serialized = []

  for (let i = 0; i < form.elements.length; i++) {
    let field = form.elements[i]

    if (
      !['checkbox', 'radio', 'button'].includes(field.type) ||
      field.checked
    ) {
      serialized.push(
        encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value)
      )
    }
  }
  serialized.push(encodeURIComponent(clickedButton.getAttribute('name')) + '=')

  // Combine the pairs into a single string and replace all %-encoded spaces to
  // the '+' character; matches the behavior of browser form submissions.
  return serialized.join('&').replace( /%20/g, '+' )
}

// async html
Alpine.data('frame', function (href, lazy = false) {
  return {
    content: null,
    insert(target, type = 'div') {
      const element = document.createElement(type)
      element.innerHTML = this.content
      target.appendChild(element)
    },
    replace(target, outer = true) {
      outer
        ? (target.outerHTML = this.content)
        : (target.innerHTML = this.content)
    },
    evtHandler: {
      ['@click.prevent']() {
        this.trigger()
      },
    },
    async trigger() {
      const url = new URL(href, window.location)
      url.searchParams.append('_ajax', true)
      url.searchParams.append('pimcore_nocache', true)

      // prevent attacks
      if (
        process.env.NODE_ENV !== 'development' &&
        !(window.location.hostname === url.hostname || !url.hostname.length)
      )
        return

      try {
        const resp = await fetch(url.href, FETCH_CONFIG)
        const content = await resp.text()
        if (content && content.trim().length > 0) this.content = content
      } catch (error) {
        console.warn('Error loading frame:', error)
      }
    },
    init() {
      if(!lazy) this.trigger()
    },
  }
})

window.Alpine = Alpine
Alpine.start()
